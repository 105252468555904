import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessage, useIntl } from 'react-intl'
import LastSyncedFormatter from './LastSyncedFormatter'
import { FC, useMemo } from 'react'
import { SourceName } from '../../enums'
import { hapiSyncCadence } from '../../utils/hapiSourcesSyncCadence'

export const BRANDS = {
    [SourceName.AppleHealth]: {
        alt: 'Apple Health',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/85a9e9cc-5865-4fa3-210f-ae3cf98abc00/cms'
    },
    [SourceName.Fitbit]: {
        alt: 'Fitbit',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c8c5824c-2eb0-4b90-73d6-6764ee0b2000/cms'
    },
    [SourceName.Garmin]: {
        alt: 'Garmin',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3d396330-bd22-49d3-af9d-b9c0dedf3200/cms'
    },
    [SourceName.GoogleFit]: {
        alt: 'Google Fit',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/6563914d-6673-4ed9-9ec5-d3ea13334000/cms'
    },
    [SourceName.iHealth]: {
        alt: 'iHealth',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5d5a356d-d5e3-4dd0-1257-d51d4ed59b00/cms'
    },
    [SourceName.MapMyFitness]: {
        alt: 'Map My Fitness',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/300eb48a-c979-4d95-371d-d8633fa38900/cms'
    },
    [SourceName.MyFitnessPal]: {
        alt: 'My Fitness Pal',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ba4064e7-8ce2-407a-0b68-bcd56b082500/cms'
    },
    [SourceName.Oura]: {
        alt: 'Oura',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/2795b1cd-1236-4314-4800-b82fd9342c00/cms'
    },
    [SourceName.Strava]: {
        alt: 'Strava',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/db1143ce-0d75-42a8-e8f5-6b12daecc300/cms'
    },
    [SourceName.Vitadock]: {
        alt: 'VitaDock',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d41685d0-7a8c-4c59-2e60-0371b1f55f00/cms'
    },
    [SourceName.Withings]: {
        alt: 'Withings',
        src: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/7df319bb-1904-415a-85cf-034b04f4dd00/cms'
    }
} as const

const CONNECTED_TEXT = defineMessage({
    defaultMessage: 'Connected',
    description: 'The text representing the connection status of the source'
})

const NOT_SYNCED_YET_TEXT = defineMessage({
    defaultMessage: 'Not synced yet',
    description:
        'The text displayed when a source is connected but data is not yet synced'
})

type ConnectedSourceProps = {
    sourceName: SourceName
    lastSynced?: Date
    showSourceName?: boolean
}

const ConnectedSource: FC<ConnectedSourceProps> = ({
    sourceName,
    lastSynced,
    showSourceName = true
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const brand = useMemo(() => {
        if (sourceName === SourceName.AppleHealthHAPI) {
            return BRANDS[SourceName.AppleHealth]
        }
        if (Object.prototype.hasOwnProperty.call(BRANDS, sourceName)) {
            // Typescript doesn't know that sourceName is a keyof typeof BRANDS but it's safe to cast at this point
            return BRANDS[sourceName as keyof typeof BRANDS]
        }
    }, [sourceName])

    if (!brand) {
        return null
    }

    const syncCadence =
        sourceName !== SourceName.AppleHealth &&
        sourceName !== SourceName.AppleHealthHAPI &&
        sourceName !== SourceName.Oura
            ? hapiSyncCadence[sourceName]
            : undefined

    return (
        <Stack gap={0.5}>
            <Stack
                direction="row"
                paddingLeft={isSmallScreen ? 2 : 3}
                padding={
                    brand.alt === 'Apple Health' || brand.alt === 'Oura'
                        ? 0
                        : undefined
                }
                alignItems="center"
                gap={3}
            >
                <img src={brand.src} alt={brand.alt} width={60} />
                <Box>
                    {showSourceName && (
                        <CoreTypography variant="h5" gutterBottom>
                            {brand.alt}
                        </CoreTypography>
                    )}
                    <CoreTypography variant="body1">
                        {formatMessage(CONNECTED_TEXT)}
                    </CoreTypography>
                    {lastSynced ? (
                        <LastSyncedFormatter
                            syncDate={new Date(lastSynced)}
                            comparedTo={new Date()}
                        />
                    ) : (
                        <CoreTypography
                            variant="body1"
                            color="gray"
                            gutterBottom
                        >
                            {formatMessage(NOT_SYNCED_YET_TEXT)}
                        </CoreTypography>
                    )}
                </Box>
            </Stack>
            <CoreTypography variant="body2">
                {!!syncCadence && formatMessage(syncCadence)}
            </CoreTypography>
        </Stack>
    )
}

export default ConnectedSource
