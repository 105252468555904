export const SourceName = {
    AppleHealth: 'apple-health',
    AppleHealthHAPI: 'apple-health-hapi',
    Fitbit: 'fitbit-hapi',
    Garmin: 'garmin-hapi',
    GoogleFit: 'google-fit-hapi',
    iHealth: 'ihealth-hapi',
    Oura: 'oura',
    MapMyFitness: 'map-my-fitness-hapi',
    MyFitnessPal: 'my-fitness-pal-hapi',
    Strava: 'strava-hapi',
    Withings: 'withings-hapi',
    Vitadock: 'vitadock-hapi'
} as const

export type SourceName = (typeof SourceName)[keyof typeof SourceName]
